// CORE CONFIG
export const themes = {
  'light': {
    'text': '#222',
    'muted': '#777',
    'pale': '#f0f0f0',
    'background': '#fff'
  },
  'dark': {
    'text': '#fff',
    'muted': '#bbb',
    'pale': '#333',
    'background': '#000'
  },
  'blue':{
	'text': '#fff',
    'muted': '#777',
    'pale': '#f0f0f0',
    'background': '#206095'

  },
  'other':{
	'background':'#B22234',
	'text': '#fff'
  }
}

// CONTENT CONFIG : 
export const regions = [
	{
		code: 'E12000001',
		name: 'North East'
	},
	{
		code: 'E12000002',
		name: 'North West'
	},
	{
		code: 'E12000003',
		name: 'Yorkshire and The Humber'
	},
	{
		code: 'E12000004',
		name: 'East Midlands'
	},
	{
		code: 'E12000005',
		name: 'West Midlands'
	},
	{
		code: 'E12000006',
		name: 'East of England'
	},
	{
		code: 'E12000007',
		name: 'London'
	},
	{
		code: 'E12000008',
		name: 'South East'
	},
	{
		code: 'E12000009',
		name: 'South West'
	}
];

