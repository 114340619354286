<script>
    import { themes } from '../config.js';
      import { getContext } from 'svelte';
      export let theme = getContext('theme');
      export let filled = false;
      export let center = true;
  </script>
  
  <nav style="border-bottom-color: {themes[theme]['muted']}; {filled ? 'background-color: ' +  themes[theme]['background'] + ';' : ''}">
    <div class="col-wide middle" class:center>
          <a href="https://gga.org/aif-58/">
              <picture>
                  {#if theme == 'dark'}
                  <img src="./img/gga-logo.png" alt="GGA" style="
                  device-width;">
                  {:else if filled == true}
                  <img src="./img/gga-logo.png" alt="GGA" style="
                  device-width;">
                  {:else}
                  <img src="./img/gga-logo.png" alt="GGA" style="
                  device-width;">
                  {/if}
              </picture>
          </a>
    </div>
  </nav>
  
  <style>
      nav {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          position: relative;
          height: 36px;
          margin-top: -36px;
          z-index: 1;
      }
      picture {
          position: relative;
          top: 40px;
          padding: 0 15px;
      }
      img {
          width: 100px;
          padding-top: 10%;
      }
      a img:hover {
          cursor: pointer;
      }
  </style>