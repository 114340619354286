<script>
	import { setContext, onMount } from "svelte";
	import { getData, setColors, getMotion } from "./utils.js";
	import { themes, regions } from "./config.js";
	import Header from "./layout/Header.svelte";
	import Section from "./layout/Section.svelte";
	import Scroller from "./layout/Scroller.svelte";
	import Filler from "./layout/Filler.svelte";
	import Divider from "./layout/Divider.svelte";
	import Map from "./map/Map.svelte";
	import Em from "./ui/Em.svelte";
	import Arrow from "./ui/Arrow.svelte";
	import GGAHeader from "./layout/GGAHeader.svelte";


	// STYLE CONFIG
	// Set theme globally (options are 'light' or 'dark')
	let theme = "light";
	setContext("theme", theme);
	setColors(themes, theme);

	// SCROLLYTELLING CONFIG
	// Config
	const threshold = 0.65;
	// State
	let index = [];
	let indexPrev = [];
	onMount(() => {
		indexPrev = [...index];
	});
	// State
	let animation = getMotion(); // Set animation preference depending on browser preference

	// MAP CODE
	// Config
	const mapstyle =
		"https://bothness.github.io/ons-basemaps/data/style-omt.json";
	const mapbounds = {
		ew: [
			[18.423, 33.924],
			[28.0473,26.2041]
			
		],
		fareham: [
			[27.8493, 13.1339],
			[24.6849, 22.3285]
			
		],
		newport: [
			[29.1549,19.0154],
			[35.5296,18.6657]
			
		],
		ew1: [
			[ 22.345,32.654],
			[-25.2041,28.0473]
			
		],
		fareham1: [
			
			[-15.5296,8.6657],
			[-8.1549,19.0154]
			
			
		],
		newport1: [
			
			[15.5296,8.6657],
			[-18.1549,19.0154]
			
		],
	};
	// State
	let map = null;

	// Actions for MAP scroller
	const mapActions = [
		() => {
			map.fitBounds(mapbounds.ew, {animate: animation});
		},
		() => {
			map.fitBounds(mapbounds.fareham, {animate: animation});
		},
		() => {
			map.fitBounds(mapbounds.newport, {animate: animation});
		},
		() => {
			map.fitBounds(mapbounds.ew1, {animate: animation});
		},
		() => {
			map.fitBounds(mapbounds.fareham1, {animate: animation});
		},
		() => {
			map.fitBounds(mapbounds.newport1, {animate: animation});
		},

	];

	// Reactive code to trigger MAP actions
	$: if (map && index[1] != indexPrev[1]) {
		if (mapActions[+index[1]]) {
			mapActions[+index[1]]();
		}
		indexPrev[1] = index[1];
	}
</script>

<GGAHeader></GGAHeader>
<Header bgimage="./img/bg-dark.jpg" bgfixed={true} theme="blue" >
	<h1>How the Biden administration could build better US-Africa multilateral relations</h1>
	
	<p style="text-align: center;"> By Nompumelelo Mtsweni and Susan Russell</p>
	<div class="text-shadow" style="margin-top: 48px;">
		<Arrow color="black" {animation}>Scroll to begin</Arrow>
	</div>
</Header>

<Header bgimage="./img/JoeBiden.jpg" bgfixed={true} theme="blue" style="max-width: 100%;" >
	
<p style="margin-top: 600px;margin-left:150px">
	<br>
	<br>
	The inauguration of United States (US) President Joseph R. Biden last year (2020) on the 20th of November has been praised by some Africans and condemned by others. 
	
</p>

</Header>


<Filler >



	<p>
		While the praise is due to the optimistic comments he has made on the promise to build back better <strong>US-Africa policy</strong> some African observers have expressed reservations based on scepticism on whether Biden will implement friendlier policies towards the continent.</p>
		<blockquote class="text-indent">
			"As far as foreign policy towards Africa is concerned, I do not expect substantive change. Biden will be different than Trump, as Barack Obama was different than George W Bush.

He will speak softly and continue the knee-on-the-neck policy to secure zero-sum advantage to a few predatory capitalists that are already well-positioned in countries such as Somalia."<br><br>
Abukar Arman – Former Somalia special envoy to the US, Somalia
<br><br>
</blockquote>
		
		<Divider></Divider>
		<blockquote class="text-indent">
"While I am not sure that [the] US-Africa relationship will change dramatically for the better with Biden, we can do without Trump’s total disrespect and contempt for Africa, Africans and non-white peoples. His absence from the world stage will not be missed."<br><br> Chika Okeke-Agulu – Art historian, professor of African and African Diaspora Art at US-based Princeton University, Nigeria

<br><br>
	</blockquote>


</Filler>
<Filler theme="other">
	<p class="mb">
		Biden has, however, promised to strengthen US relations with Africa and repair the legacy left by former US president Donald Trump’s tumultuous four-year presidency. 
		<br>
		<br>
		<iframe width="100%" height="515" src="https://www.youtube.com/embed/HcMFmoTCdcU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" rel=0 allowfullscreen></iframe>
	<br>
	</p>
	
	

</Filler>

<Filler theme="light">
	<iframe src='https://flo.uri.sh/visualisation/6561159/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/6561159/?utm_source=embed&utm_campaign=visualisation/6561159' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>
	<iframe src='https://flo.uri.sh/visualisation/6577861/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/6577861/?utm_source=embed&utm_campaign=visualisation/6577861' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>

<br>
	<p>Here, we explore some of the ways the Biden administration could build better US-Africa multilateral relations, focusing on <strong>democracy, economic ties, and trade</strong>.</p>


</Filler>


<Section theme="blue" >
	<h2 style="text-align:left">Democracy</h2>

	<p > 
		With the governance challenges many African nations face, including elites that cling to power, and a lack of transparency around the legitimacy of elections, the Biden administration could, for instance, provide financial and other support to independent electoral commissions that meet international standards.
		
	</p>
	<br>
	<iframe width="100%" height="600px" src="https://www.youtube.com/embed/GRBeyJGB_Y0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	
	<br>
	<br>

	
</Section>


<Section >
	<h2>Economic and trade relations</h2>
	<p class="mb">
		
In addition, the many pressing economic challenges facing Africa,including the looming crisis of excessively high youth unemployment rates and over-reliance on single commodity exports, and corruption, offer American organisations such as the US Agency for International Development (USAID) an opportunity to help develop Africa’s emerging markets and switch the rules of engagement from a humanitarian aid-centric to a more economic empowering approach. 
	</p>
	<br>
</Section>

<Scroller {threshold} bind:index={index[1]}>
	<div slot="background">
		<figure>
			<div class="col-full height-full">
				<Map style={mapstyle} bind:map interactive={false} />
			</div>
		</figure>
	</div>

	<div slot="foreground">
		<section>
			<div class="col-medium">
				<p>
					<strong>Libya</strong> : Total USAID assistance (2020 - 2021) <Em>$91 million</Em>
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
				<strong>Chad</strong> : Total USAID assistance (2020 - 2021)<Em>$155 million</Em> 
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					<strong>Sudan</strong> : Total USAID assistance (2020 - 2021) <Em>$674 million</Em> 
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					<strong>Morocco</strong> : Total USAID assistance (2020 - 2021) <Em>$67 million</Em> <br> 
					<strong>Algeria</strong> : Total USAID assistance (2020 - 2021) <Em>$28 million</Em>
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					<strong>Senegal </strong> : Total USAID assistance (2020 - 2021)	 <Em>$217 million</Em> 
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					<strong>Burkina Faso </strong> : Total USAID assistance (2020 - 2021)<Em style="background-color: red">$101 million</Em> 
			</div>
		</section>
	</div>
</Scroller>

<Divider />

<Section theme="other" style="height:100vh">
	<h2>The role of African Growth and Opportunity Act (AGOA)</h2>

	<p>
		AGOA must work to create trade hubs and facilities to trade African products to the American markets. The Biden administration can assist in improving export opportunities for African goods by supporting manufacturing on the continent and  in filling in the gaps in the AGOA framework to improve industrialisation in Africa.

	</p>
	<br>
	<br>
	<iframe src='https://flo.uri.sh/visualisation/6559544/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/6559544/?utm_source=embed&utm_campaign=visualisation/6559544' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>
<br>
</Section>

	

<Section theme="blue">
	<p>African Union and USAID have highlighted the importance of the African Wealth Opportunities Act to boost trade relations between Africa and the US.</p>
	<p>
		More investment from the US, signalling an era of renewed relations with Africa will be necessary to improve democracy, economy, and trade relations across the continent.  

	</p>
	<br>
	<iframe src='https://flo.uri.sh/visualisation/6559868/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/6559868/?utm_source=embed&utm_campaign=visualisation/6559868' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>
	<br>
	<p>If, as Joe Biden has proclaimed, <a href="https://www.voanews.com/usa/biden-says-america-back-us-allies-arent-so-sure" target="blank" style="color: black;">“America is back” </a> then it should not be business as usual in <strong style="color: black;">Africa</strong>.</p>
<br>
</Section>
<Section style="text-align: center;" >
	<h4>Brought to you by Good Governance Africa in partnership with the University of the Witwatersrand's African Centre for the Study of the US</h4>
	
	<p class="mb">
		<u>Credits</u><br><br>
            Images: AFP, GettyImages and Pexels <br>
            Data visualisations: Flourish studio<br>
            Map: Svelte-mapbox <br>
            Data source for the map: USAID <a href="https://explorer.usaid.gov/cd" target="blank">all agencies</a> 2020 - 2021
	</p>
	<div style="display:flex;justify:center;margin-left: 1.3vw;padding-left:18%;">

		<div>

				<a href="https://www.linkedin.com/company/good-governance-africa/" target="blank" class="link">
					<img border="0" alt="GGA-Linkedin" src="./img/linkedin.png" width="5%" height="5%" style="margin:5px 10px "></a>
				
				
					
				<a href="https://twitter.com/GGA_org" class="link" target="blank" >
					<img border="0" alt="GGA-twitter" src="./img/twitter.png" width="5%" height="5%" style="margin:5px 10px ">
				</a>
	
				<a href="https://www.facebook.com/goodgovernanceafrica/" class="link"  target="blank" >
					<img border="0" alt="GGA-Facebook" src="./img/facebook.png" width="5%" height="5%" style="margin:5px 10px ">
				</a>
	
				<a href="https://www.youtube.com/channel/UCClrQ36ZfIw0UD_jxiFDYLQ" class="link" target="blank" >
					<img border="0" alt="GGA-Youtube" src="./img/youtube.png" width="5%" height="5%" style="margin:5px 10px ">
				</a>
	
				<a href="https://www.instagram.com/ggafrica_org/" class="link" target="blank" >
					<img border="0" alt="GGA-Instagram" src="./img/instagram.png" width="5%" height="5%" style="margin:5px 10px "></a>
				</div>
			
	</div>
	<br>
</Section>



